import React from "react"

import Logo from '../../components/logo/logo'
import Footer from '../../containers/footer/footer'
import QuemSomos from '../../containers/quemsomos-new/quemsomos'
import QueFazemos from '../../containers/QueFazemos/quefazemos'
import Cases from '../../containers/cases/cases'
import Servicos from '../../components/servicos/servicos'
import Landingpage from '../../containers/landingpage/landingpage'
import LandingpageAnimated from '../../containers/landingpage-animated/landingpage-animated'
import Contato from '../../components/contato/contato'
import Novidades from '../../components/novidades/novidades'
import Missao from '../../components/valores/valores'
import Citacao from '../../components/citacao/citacao'
import Produtos from "../../components/produtos/produtos"
import CallToAction from '../../containers/call-to-action/call-to-action'
import PorqueNos from '../../containers/PorqueNos/porquenos'
import GalleryContainer from '../../containers/gallery/gallery-container'
import Funcionamento from '../../components/Funcionamento/Funcionamento'
import Parceiros from '../../components/parceiros/parceiros'

import { StaticQuery, graphql } from "gatsby"

import "../../styles/index.scss"
import Equipe from "../../containers/equipe/equipe"
import PdfGallery from "../../containers/pdf-gallery/pdf-gallery.container"
import Marcas from "../../containers/marcas-produtos/marcas-produtos"


class IndexLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarStatus: undefined,
      contentStatus: "",
      whatsappLink: "",
    }
  }

  render() {
    return (
      <div key={101}>
        {generateMiddle(this.props.pageOrder)}
        <Footer key={123213} logo={<Logo />} />
      </div>
    )
  }
}

const generateMiddle = contents => {
  let middle = []
  contents.forEach(content => {
    switch (content) {
      case "landing":
        middle.push(
          <Landingpage key={content}>
          </Landingpage>)
        break
      case "landing-animated":
        middle.push(
          <LandingpageAnimated key={content}>
          </LandingpageAnimated>)
        break
      case "Servicos":
        middle.push(<Servicos key={content} titulo="Serviços" />)
        break
      case "calltoaction":
        middle.push(<CallToAction key={content} />)
        break
      case "galeria":
        middle.push(<GalleryContainer key={content} />)
        break
      case "equipe":
        middle.push(<Equipe key={content} />)
        break
      case "contato":
        middle.push(<Contato key={content} />)
        break
      case "pdf":
        middle.push(<PdfGallery key={content} />)
        break
      case "citacao":
        middle.push(<Citacao key={content}></Citacao>)
        break
      case "marcas":
        middle.push(<Marcas key={content}></Marcas>)
        break
      case "produtos":
        middle.push(<Produtos key={content}></Produtos>)
        break
      case "QuemSomos":
        middle.push(<QuemSomos key={content}></QuemSomos>)
        break
      case "QueFazemos":
        middle.push(<QueFazemos key={content}></QueFazemos>)
        break
      case "cases":
        middle.push(<Cases key={content}></Cases>)
        break
      case "PorqueNos":
        middle.push(<PorqueNos key={content}></PorqueNos>)
        break
      case "Novidades":
        middle.push(<Novidades key={content}></Novidades>)
        break
      case "Missão":
        middle.push(<Missao key={content}></Missao>)
        break
      case "Funcionamento":
        middle.push(<Funcionamento key={content}></Funcionamento>)
        break
      case "Parceiros":
          middle.push(<Parceiros key={content}></Parceiros>)
          break
      default:
        break
    }
  })
  return middle
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        metadatasJson {
          containerorder
        }
      }
    `}
    render={data => (
      <IndexLayout key={3000} pageOrder={data.metadatasJson.containerorder} {...props} />
    )}
  />
)
