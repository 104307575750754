import React from 'react'
import TopBar from '../../containers/new-topbar/new-topbar';
import Logo from '../../components/logo/logo';
import Navigation from '../../components/navigation/navigation';
import TopAppend from '../../containers/topAppend/topAppend';
import TelefoneContato from '../../components/telefoneContato/telefoneContato';
import BtnWhatsapp from '../../components/btn-whatsapp/btn-whatsapp';
import BtnEmail from '../../components/btn-email/btn-email';
import BtnSkype from '../../components/btn-skype/btn-skype';
import BtnFacebook from '../../components/btn-facebook/btn-facebook';
import BtnInstagram from '../../components/btn-instagram/btn-instagram';
import AllPurposeBtn from '../../components/all-purpose-btn/all-purpose-btn';
import SideBarPush from '../../containers/sidebar-push/sidebar-push';
import { StaticQuery, graphql } from "gatsby"

class TopBarFull extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sidebarStatus: undefined,
            contentStatus: "",
            whatsappLink: ""
        }
    }

    toggleOffSideBar = () => {
        this.setState({
            sidebarStatus: false,
            contentStatus: "content-hide-sidebar",
        })
    }

    toggleOnSideBar = () => {
        this.setState({
            sidebarStatus: true,
            contentStatus: "content-show-sidebar",
        })

    }

    chooseBTN = (types) => {
        let btnArray = [];
        types.topbarBTN.forEach((type) => {
            switch (type) {
                case "whatsapp":
                    btnArray.push(
                    <BtnWhatsapp key = {type}
                    link={this.state.whatsappLink}
                    ></BtnWhatsapp>)
                    break;
                case "email":
                    btnArray.push(<BtnEmail key={type}></BtnEmail>)
                    break;
                case "skype":
                    btnArray.push(<BtnSkype key={type}></BtnSkype>)
                    break;
                case "facebook":
                    btnArray.push(<BtnFacebook key={type}></BtnFacebook>)
                    break;
                case "instagram":
                    btnArray.push(<BtnInstagram key={type}></BtnInstagram>)
                    break;
                case "generic":
                    btnArray.push(<AllPurposeBtn key={type}
                    btnText={types.genericBtnText}
                    link={types.genericBtnLink}
                    ></AllPurposeBtn>)
                    break;
        
              default:
                break;
            }
        });
        return btnArray;
    }

    render() {
        return (
            <div>
                <TopBar
                    action={this.toggleOnSideBar}
                    logo={<Logo></Logo>}
                    navigation={<Navigation></Navigation>}
                    topAppend={
                        <TopAppend
                            content={
                                <>
                                    <TelefoneContato
                                        tel={this.props.content.contato.telefone[0]}
                                    ></TelefoneContato>
                                    {this.chooseBTN(this.props.content.topbar)}
                                </>
                            }
                        ></TopAppend>
                    }
                ></TopBar>
                <SideBarPush
                    logo={<Logo></Logo>}
                    action={this.toggleOffSideBar}
                    state={this.state.sidebarStatus}>
                </SideBarPush>
            </div>
        )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
        {
            metadatasJson {
                contato {
                    telefone
                    whatsapp
                    email
                }         
                topbar {
                    topbarBTN
                    genericBtnText
                    genericBtnLink
                }
                gaID
                gtmID
            }
        }
    `}
        render={data =>
            <TopBarFull content={data.metadatasJson}{...props}></TopBarFull>
        }
    />)