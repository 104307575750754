import React from 'react';
import './produtos.scss';
import { StaticQuery } from 'gatsby';


const Produtos = ({title1, title2, topics}) => (

    <div className="quemsomos-boxed" >
        <div className="quemsomos__wrapper">

            <div className="quemsomos-titulo-boxed">
                <div className="quemsomos-titulo-div">
                    <p className="titulo quem-somos-titulo2">{title2}</p>
                    <p className="quem-somos-titulo">{title1}</p>
                </div>
            </div>
            
            <div className="quemsomos-topicos">
                {generateTopics(topics)}
            </div>
        </div>

     
    </div>
)

const generateTopics = (topics) => {
    let tmp = []

    topics.forEach((element, i) => {
        tmp.push(
            <div key={i} className="quemsomos-topicos-icones">
                <div className="item">
                    <h2 className="h2-quemsomos">{element.title}</h2>
                    <p className="p-quemsomos"> {element.text} </p>
                </div>
            </div>
        )
    });

    return tmp
}

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {
            produtos {
                title1
                title2
                topics {
                    title
                    text
                }
            }      
        }
      }      
  `}
        render={data =>
            <Produtos 
            title1={data.metadatasJson.produtos.title1}{...props} 
            title2={data.metadatasJson.produtos.title2}{...props} 
            topics={data.metadatasJson.produtos.topics}{...props}
            ></Produtos>
        }
    />)



