import React from 'react'
import image from "../../images/icon-vertical.png"
import './logo.scss'
// import Img from "gatsby-image"


const Logo = () => {
    return (
        <img className = "logo" alt= "Logo da empresa" src = {image}>
        </img>
    )
}

export default Logo
