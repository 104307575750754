import React from 'react';
import './contato.scss'
import { StaticQuery, graphql } from "gatsby"

const Contato = ({ contato, facebook }) => {
    return (
        <div className="contato-container" id="contato">
            <div className="contato-wrapper">

                <div className="contato-titulo">
                    <h1 className="titulo">Contato</h1>
                </div>

                <div className="contato-conteudo">
                    <div className="contato-info">
                        <div className="card-contato contato-card__header card-contato--spacing">
                            <div className="contato-card-header__icon">
                                <i className="material-icons icon-contato">alarm</i>
                            </div>
                            <div className="contato-card-header__content">
                                <p> Horário de funcionamento </p>
                                {generateHorario(contato.horario)}

                            </div>
                        </div>
                        <div className="card-contato contato-card__header card-contato--spacing">
                            <div className="contato-card-header__icon">
                                <i className="material-icons icon-contato">phone</i>
                            </div>
                            <div className="contato-card-header__content">
                                <p> Telefone </p>
                                {generateTelefone(contato.telefone, contato.telObs, facebook)}

                            </div>
                        </div>
                        <div className="card-contato contato-card__header card-contato--spacing">
                            <div className="contato-card-header__icon">
                                <i className="material-icons icon-contato">mail_outline</i>
                            </div>
                            <div className="contato-card-header__content">
                                <p> E-mail </p>
                                <a href={"mailto:" + contato.email}>
                                    <p className="row-contato">{contato.email}</p>
                                </a>
                            </div>
                            {/* <div className="contato-card-header__content">
                                <p> Facebook </p>
                                <a href={facebook}>
                                    <p className="row-contato">{facebook}</p>
                                </a>
                            </div> */}
                        </div>


                        <div className="card-contato contato-card__header card-contato--spacing">
                            <div className="contato-card-header__icon">
                                <i className="material-icons icon-contato">location_on</i>
                            </div>
                            <div className="contato-card-header__content">
                                <p> Localização </p>
                                <a href={"https://www.google.com/maps/" + contato.addressLink}>
                                    <p className="row-contato">{contato.address}</p>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="card-contato contato-mapa card-contato--spacing">
                        <iframe title="mapa" className="mapa-google" width="100%" height="300px !important" frameBorder="0" style={{ border: 0 }}
                            src={"https://www.google.com/maps/embed/v1/" + contato.addressLink}
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {            
            contato {
                telObs
                telefone
                whatsapp
                email
                address
                addressLink
                horario
            }
            facebook        
        }
      }      
  `}
        render={data =>
            <Contato contato={data.metadatasJson.contato}{...props} facebook = {data.metadatasJson.facebook}></Contato>
        }
    />)

const generateTelefone = (tels, telObs, facebook) => {
    let list = []
    tels.forEach((tel, i) => {
        const temp = telObs[i] === "" ? "" : `(${telObs[i]})`
        if (tel.length > 12) {
            list.push(
                <a key={tel} href={"tel: +" + tel}>
                    <p className="row-contato">
                        ({tel.substring(2, 4)}) {tel.substring(4, 9)} - {tel.substr(-4)} {temp}
                    </p>
                </a>)
        } else {
            list.push(
                <a key={tel} href={"tel: +" + tel}>
                    <p className="row-contato">
                        ({tel.substring(2, 4)}) {tel.substring(4, 8)} - {tel.substr(-4)} {temp}
                    </p>
                </a>)
        }
    });
    return list
}

const generateHorario = (horarios) => {
    let list = []
    horarios.forEach((horario, i) => {
        list.push(<p key={i} className="row-contato">{horario}</p>)
    })

    return list
}