import React from "react"
import ReactGA from 'react-ga';
import { StaticQuery, graphql } from "gatsby"

import './btn-email.scss'
const BtnEmail = ({content})=> {
    ReactGA.initialize(content.gaID);
    return (
      <ReactGA.OutboundLink className="botao" eventLabel="email"  to={`mailto:${content.contato.email}`}    target="_blank">
          <i className={"far fa-envelope whatsapp-icon"}></i>
          <p className={"p-botao-wpp"}> Fale Conosco </p>
    </ReactGA.OutboundLink>
  )
}

export default props => (
  <StaticQuery
      query={graphql`
    {
      metadatasJson {
        contato {
          telefone
          whatsapp
          email
        }
        gaID
        gtmID
      }
    }
`}
      render={data =>
          <BtnEmail content={data.metadatasJson}{...props}></BtnEmail>
      }
  />)