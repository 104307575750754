import React from 'react'
import './navigation.scss'
import { StaticQuery, graphql } from "gatsby"

const Navigation = ({ sessions }) => {
    return (
        <div className="sessions" >
            {generateItens(sessions)}
        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {
          sessions {
            sessionName
            sessionId
            sessionContents
          }
        }
      }      
  `}
        render={data =>
            <Navigation sessions={data.metadatasJson.sessions}{...props}></Navigation>
        }
    />)

const generateItens = (itensContent) => {
    if (itensContent === undefined) return "Esqueceu de passar as Sessions"
    let itens = []
    itensContent.forEach(item => {
        if (item.sessionContents[0] === "self") {
            itens.push(
                    <a className=" session-item" key={item.sessionName} href={"/#"+item.sessionId} style={{textDecoration: 'none' }}>
                        {item.sessionName[0].toUpperCase()}{item.sessionName.substring(1)}
                    </a>
            )
            return
        }
        if (item.sessionContents[0] === item.sessionName) {
            itens.push(
                <p className="session-item" key={item.sessionName}>
                    <a href={"/"+item.sessionName} style={{ textDecoration: 'none' }}>
                        {item.sessionName[0].toUpperCase()}{item.sessionName.substring(1)}
                    </a>
                </p>
            )
            return
        }
        if (item.sessionContents.length > 0) {

            itens.push(
                <div className="session-dropdown" key={item.sessionName}>
                    <a className="session-display" style={{ textDecoration: 'none' }}>
                        {item.sessionName[0].toUpperCase()}{item.sessionName.substring(1)}
                    </a>
                    <div className="navbar-dropdown">
                        {generateSubItens(item.sessionContents)}
                    </div>
                </div>
            )

        }
    });

    itens.slice(itens.length - 3);

    return itens
}
const generateSubItens = (contents) => {
    let subitens = []
    contents.forEach(content => {
        subitens.push(
            <a className="navbar-item" href={"/"+content} key={content}>
                {content[0].toUpperCase()}{content.substring(1)}
            </a>
        )
    });
    return subitens
}