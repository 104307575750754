import React from "react"
import Img from "gatsby-image"
import Hammer from "react-hammerjs"
import "./marcas.scss"


class Marcas extends React.Component {
    state = {
        displayFoto: false,
        selectedFoto: this.props.fotos[0],
        selectedFotoIndex: 0,
        posicao: {
            transform: `translateX(-${this.rotacaoGrid}%)`
        }
    }
    rotacaoGrid = 1
    state = { posicao: { transform: `translateX(-${this.rotacaoGrid}%)` } }

    componentDidMount() {
        this.rotacaoInc = this.incrementoRotacao()
        this.maxSwipes = this.props.fotos.length - 2
        // not mobile
        if (this.rotacaoInc !== 100) this.maxSwipes -= 3
    }

    render() {
        return (
            <div className="parceiros-div-principal">

                <i className="material-icons seta-parc" style={{ fontSize: '200%' }} onClick={this.RotacionaEsq.bind(this)}>keyboard_arrow_left</i>
                <Hammer onSwipeRight={this.RotacionaEsq.bind(this)} onSwipeLeft={this.RotacionaDir.bind(this)} >
                    <div id="parceiros-boxed" >
                        <div id="parceiros" style={this.state.posicao}>
                            {this.generateGridImage(this.props.fotos)}
                        </div>
                    </div>
                </Hammer>
                <i className="material-icons seta-parc" style={{ fontSize: '200%' }} onClick={this.RotacionaDir.bind(this)}>keyboard_arrow_right</i>
            </div>
        )
    }

    generateGridImage(fotos) {
        let cards = []
        fotos.forEach((foto, i) => {
            cards.push(
                <div key={i + 'sdfdsf'} className="imagem-ind">
                    <Img className="parceiros-logo" alt="description" style={{ width: '120px', height: 'auto' }} fluid={foto.node.fluid}></Img>
                </div>
            )
        })
        return cards
    }

    incrementoRotacao() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ) {
            return 100;
        }
        else {
            return 25;
        }
    }

    RotacionaEsq() {
        if (this.rotacaoGrid < 1)
            this.rotacaoGrid = this.rotacaoGrid + this.rotacaoInc
        this.setState({ posicao: { transform: `translateX(${this.rotacaoGrid}%)` } })
    }

    RotacionaDir() {
        if (this.rotacaoGrid > -(this.rotacaoInc * this.maxSwipes)) {
            this.rotacaoGrid = this.rotacaoGrid - this.rotacaoInc
        }
        this.setState({ posicao: { transform: `translateX(${this.rotacaoGrid}%)` } })
    }
}

export default Marcas
