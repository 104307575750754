import React from 'react';
import './cases.scss'
import { StaticQuery, graphql } from "gatsby"
import CasesComp from '../../components/cases/cases'

const Cases = ({ cases, fotos }) => {
    return (
        <div className="cases-container" id="cases">
            <div className="quemSomos-wrapper">
                <div className="cases-title">
                    <h1>Cases</h1>
                </div>
                <CasesComp cases={cases} fotos={fotos}></CasesComp>
            </div>
        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {            
            cases {
                title
                content
            }
        }
        allImageSharp(filter: {original: {src: {regex: "/.*cases.*/"}}}) {
            edges {
                node {
                    id
                    original {
                        width
                        height
                        src
                    }
                    fluid (maxWidth: 600){
                        base64
                        tracedSVG
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
      }      
  `}
        render={data =>
            <Cases
                cases={data.metadatasJson.cases}
                fotos={data.allImageSharp.edges}
                {...props}>
            </Cases>
        }
    />)