import React from "react"
import "./porquenos.scss"
import { setInterval } from "timers"

export default class PorqueNos extends React.Component {
    constructor() {
        super()
        this.SwichCarrouselInterval = setInterval(() => {
            this.SwichCarrousel();
        }, 2000)

        this.RotacaoControler = 1
        this.maxSwipes = 1

        this.state = {
            RotacaoValue: 0,
            RotacaoControler: 1
        }
    }   

    componentDidMount() {
        this.rotacaoInc = this.incrementoRotacao()        
    }

    SwichCarrousel() {
        if (this.RotacaoControler > 0) this.RotacionaDir();
        else this.RotacionaEsq();
        this.RotacaoControler *= -1;
    }

    handleClick(RighBtnBool) {
        if (this.SwichCarrouselInterval !== true) {
            clearInterval(this.SwichCarrouselInterval._id)
            this.SwichCarrouselInterval = true;
        }

        if (RighBtnBool) this.RotacionaDir()
        else this.RotacionaEsq()
    }

    render() {
        return (
            <div className="cases-div-principal">
                <div className="porquenos-rotation-container">
                    <div id="porquenos" style={{ transform: `translateX(${this.state.RotacaoValue}%)`}}>
                        {this.generateGridImage(this.props.calltoaction)}
                    </div>
                </div>
                <div id="markers">
                    <div className="porquenos-marker" onClick={() => this.handleClick(false)} style={{ background: this.state.RotacaoValue == 0 ? '#333333' : '' }}></div>
                    <div className="porquenos-marker" onClick={() => this.handleClick(true)} style={{ background: this.state.RotacaoValue != 0 ? '#333333' : '' }}></div>
                </div>
            </div>
        )
    }

    generateGridImage = content => {
        return content.content.map((item, i) => (
            <div className="porquenos-container" key={item + i}>
                <h1>{item.header}</h1>
                <p>{item.text}</p>
            </div>
        )
        )
    }

    incrementoRotacao() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ) {
            return 100;
        }
        else {
            return 100;
        }
    }

    RotacionaEsq() {
        if (this.state.RotacaoValue < 0)
            this.setState({ RotacaoValue: this.state.RotacaoValue + this.rotacaoInc})
    }

    RotacionaDir() {
        if (this.state.RotacaoValue > -(this.rotacaoInc * this.maxSwipes))
            this.setState({ RotacaoValue: this.state.RotacaoValue - this.rotacaoInc})
    }
}
