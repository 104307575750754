import React from 'react';
import './quefazemos.scss'
import { StaticQuery, graphql } from "gatsby"

const QueFazemos = ({ obj }) => {
    return (
        <div className="queFazemos-container">
            <div className="quemSomos-wrapper">
                <div className="quemSomos-title">
                    <h1>O que fazemos</h1>
                </div>
                <div className="quefazemos-cards">
                    {generateCards(obj)}
                </div>
            </div>
        </div>
    )
}

const generateCards = cards => cards.
    map((card,i) => (
        <div key={'quefazemos'+i} className="quefazemos-card">
            <h1>{card.title}</h1>
            <p>{card.content}</p>
        </div>
    )
    )



export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {            
            Quefazemos {
                title
                content
            }  
        }
      }      
  `}
        render={data =>
            <QueFazemos obj={data.metadatasJson.Quefazemos}{...props}></QueFazemos>
        }
    />)