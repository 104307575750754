import React from "react"
import Img from "gatsby-image"
import Hammer from "react-hammerjs"
import "./gallery-full.scss"
import logo from '../../images/icon-vertical.png'

class Gallery extends React.Component {

  render() {
    return (
      <div className="gallery-grid-full__container">
        <div
          className={"gallery-grid-holder"}
          style={{ overflow: "auto" }}
          id="galeria"
        >
          <h1 className="titulo">{this.props.title}</h1>
          <div className={"gallery-grid-wrapper"}>
            {this.generateGrid(this.props.fotos, this.props.legendas)}
          </div>
        </div>
      </div>
    )
  }

  generateGrid(fotos, legendas) {
    let cards = []
    fotos = fotos.sort((a, b) => a.node.original.src.localeCompare(b.node.original.src))
    fotos.forEach((foto, i) => {
      cards.push(
        <div
          key={i}
          className="image-holder"
        >
          <Img className="gallery__min" fluid={foto.node.fluid} />
          <p className="agua">{legendas[i]}</p>
        </div>
      )
    })
    return cards
  }

  previousPhoto() {
    if (this.state.selectedFotoIndex > 0) {
      this.setState({
        selectedFotoIndex: this.state.selectedFotoIndex - 1,
      })
    }
  }

  nextPhoto() {
    if (this.state.selectedFotoIndex < this.props.fotos.length - 1) {
      this.setState({
        selectedFotoIndex: this.state.selectedFotoIndex + 1,
      })
    }
  }

}

export default Gallery
