import React from 'react';
import './card-serviços.scss';



export default class CardServiços extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            stateAnimationNameTxt:{
                height:'0',
                animationName: 'txt-p3 ',
            },
         }

    }
    componentDidMount() {
    }
    
    checkForMobile() {

        if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true;
        }
        else {
            return false;
        }
    }
    
    startAnimation(){
        let isMobile = this.checkForMobile()
        if(!isMobile){
            this.setState({
           
                stateAnimationNameTxt:{
                    animationName: 'txt-p2 ',
                },
                stateAnimationNameTitulo:{
                    animationName: 'titulo-1 ',
                }
               
               
    
              })
        }
        else if(isMobile){

            this.setState({
               
                stateAnimationNameTxt:{
                    animationName: 'txt-p2 ',
                },
               
               
               
    
              })
        }
    }

    finishAnimation(){
        let isMobile = this.checkForMobile()
        if(!isMobile){

        this.setState({

            stateAnimationNameTxt:{
                animationName: 'txt-p3 ',
            },
            stateAnimationNameTitulo:{
                animationName: 'titulo-2 ',
            }
           
          })
        }
        else if(isMobile){
            this.setState({

                stateAnimationNameTxt:{
                    animationName: 'txt-p3 ',
                },
               
               
              })
        }
    }

    render() {
        return (
            <div className="CardColuna-boxed"  onMouseOver={this.startAnimation.bind(this)} onMouseOut={this.finishAnimation.bind(this)}  >
            {/* <div className="CardColuna-boxed"  onMouseOver={this.startAnimation.bind(this)} onMouseOut={this.finishAnimation.bind(this)} > */}
             
                <div className="titulo-cardColuna">
                    <p className="cardColuna-p dois"  style={this.state.stateAnimationNameTitulo}> {this.props.titulo}</p>

                </div>
                <div className="description-cardColuna"  style={this.state.stateAnimationNameTxt}>
                    <p className="p-card-coluna"><i className="material-icons">{this.props.icon1}</i>{this.props.description1}</p>

                </div>
            </div>
        )
    }

}

