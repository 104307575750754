import React from 'react';
import './new-topbar.scss';

const TopBar = ({ logo, navigation, topAppend, action }) => {
    return (
        <nav className="topbar">
            <div className="topbar-logo-container">
                <div className="logo-div">
                    <p className="logo">Sistema</p>
                    <p className="logo black">OPS</p>
                </div>
            </div>
            <div className="topbar-double-container">
                <div className="topbar-append-container">
                    {topAppend}
                </div>
                <div className="topbar-nav-container">
                    {navigation}
                </div>
            </div>
            <div className="topbar-menu" onClick={action}>
                <i className="material-icons fullscreen" style={{ color: '#505050', fontSize: '2.2em', padding: '10px 10px', zIndex: '200' }}>menu</i>
            </div>

        </nav>

    )

}

export default TopBar
