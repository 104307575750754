import React from 'react';
import './landingpage.scss';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import logo from '../../images/icon-vertical.png'
import Slider from '../../components/slider/slider'
const Landingpage = ({ link, content}) => {
  return (
    <div className="view--fixed view--full" id="home">
      <Img className="bg-image " fluid={link}></Img>
      <div className="view__content view--filter">
        <div className="landingpage__wrapper">
          <div className="view__content__items">
            {animatedText(content.title)}
            <p className="landingpage-text">{content.subline}</p>
          </div>
        </div>

      </div>

    </div>
  )
}

const showLogo = (visible) => {
  if (visible) {
    return <img className="landingpage-logo" alt="Logo da empresa" src={logo} ></img>
  } else {
    return null
  }
}

const animatedText = (texts) => {
  texts[0] = texts[0].replace(/'/g, '"')
  switch (texts.length) {
    case 0:
      return null   
      break;
    case 1:

      return <p className="landingpage-title"> {texts[0]}</p>
    default:
        return <p className="landingpage-title"> {texts[0]}</p>
  }
}
export default props => (
  <StaticQuery
    query={graphql`
    {
      content: metadatasJson {
        landing{
          title
          subline
          landinglogo
        }
      }
      image: allImageSharp(filter: {original: {src: {regex: "/.*landing*.*/"}}}) {
        edges {
          node {
            id
            original {
              width
              height
              src
            }
            fluid (maxWidth: 1900){
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
`}
    render={data =>
      <Landingpage 
      content={data.content.landing} 
      link={data.image.edges[0].node.fluid}
      {...props}></Landingpage>
    }
  />)