import React from "react"
import Img from "gatsby-image"
import "./cases.scss"

export default class Cases extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className="cases-div-principal">
                {this.generateGridImage(this.props.fotos)}
            </div>
        )
    }

    generateGridImage(fotos) {
        return fotos.map((foto, i) => (
            <div className="case-card-container" key={`Cases_card${i}`}>
                <div className="case-card-left">
                    <Img alt="description" className="case-card-left-IMG" fluid={foto.node.fluid}></Img>
                </div>
                <div className="case-card-right">
                    <h3 className="case-card-title">{this.props.cases[i].title}</h3>
                    {this.props.cases[i].content.map(element => <p key={element} className="case-card-p">{element}</p>)}
                </div>
            </div>
        )
        )
    }
}