import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import './parceiros.scss';
import CardParceiro from '../card-parceiro/card-parceiro';

const Parceiros = ({ parceiros, logo }) => (
    <div className="parceiros-boxed"  >
        <div id="parceiros"></div>
        <h1 className="parceiros-boxed-h">Parceiros</h1>

        <div className="parceiro">
            {createCards(parceiros, logo.sort((a, b) => a.node.original.src.localeCompare(b.node.original.src)))}
        </div>

    </div>
)

const createCards = (parceiros, logo) => (
    parceiros.map((value, i) => (
        <CardParceiro key={value.title}
            titulo={value.title}
            basico={value.content}
            img={logo[i].node.fluid.src}
        >
        </CardParceiro>
    ))
)

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {            
            parceiros {
                title
                content
            }      
        }
        allImageSharp(filter: {original: {src: {regex: "/.*parceiro.*/"}}}) {
            edges {
                node {
                    id
                    original {
                        width
                        height
                        src
                    }
                    fluid (maxWidth: 600){
                        base64
                        tracedSVG
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
      }      
  `}
        render={data =>
            <Parceiros
                parceiros={data.metadatasJson.parceiros}
                logo={data.allImageSharp.edges}
                {...props} >
            </Parceiros>
        }
    />)