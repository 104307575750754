import React from 'react'
import CardEquipe from '../../components/card-equipe/card-equipe';
import { StaticQuery, graphql } from 'gatsby';

import './equipe.scss';

const Equipe = ({content, fotos}) => {
    return(
      <div className="equipe__container">
        <div className="equipe__wrapper">
          <div className="titulo equipe__title">{content.titulo}</div>
          <CardEquipe cardsContent={content.membros} fotos={fotos}></CardEquipe>
        </div>
      </div>
    )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        content: metadatasJson {
          equipe {
            membros {
              nome
              titulo
              descricao
            }
            titulo
          }
        }
        image: allImageSharp(filter: {original: {src: {regex: "/.*equipe.*/"}}}) {
          edges {
            node {
              id
              original {
                width
                height
                src
              }
              fluid (maxWidth: 300){
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    `}
    render={data =>
      <Equipe 
      content={data.content.equipe} 
      fotos={data.image.edges}
      {...props}></Equipe>
    }
  />)