import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import './footer.scss';

import instagram from './instagram.svg'
import facebook from './facebook.svg'
import youtube from './youtube.svg'
import linkedin from './linkedin.svg'
import skype from './skype.svg'

const Footer = ({ redesSociais, empresa, navigation }) => (
  <div className="footer-box">
    <div className="legal-container">
      <div className="footer-wrapper">
        <div className="branding ">
          <p>
            Todos os direitos reservados a
            <span className={"branding--company"}> {empresa}</span>&#174;
          </p>
          <div className="branding__legal-left">
            <div>
              Desenvolvido por <a href="http://www.flydigital.com.br/"
                title="FlyDigital">
                <span className={"branding--fly-digital"}>
                  <span className={"branding--fly"}>Fly</span>
                  <span className={"branding--digital"}>Digital</span>
                </span>
              </a>
            </div>
            <div className="branding--flaticon">
              Icons made by <a href="https://www.flaticon.com/authors/flat-icons"
                title="Flat Icons">Flat Icons</a> from <a href="https://www.flaticon.com/"
                  title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const generateSocialContent = (redesSociais) => {
  let itens = []
  redesSociais.forEach(redeSocial => {
    let svg
    switch (redeSocial.rede) {
      case "facebook":
        svg = facebook
        break;
      case "instagram":
        svg = instagram
        break;
      case "youtube":
        svg = youtube
        break;
      case "linkedin":
        svg = linkedin
        break;
      case "skype":
        svg=skype
        break;
      default:
        break;
    }
    itens.push(
      <a href={redeSocial.link} target="blank_" rel="noopener noreferrer" key={redeSocial.rede} className="sociais-footer">
        <img className="sociais-footer" alt={redeSocial.rede} src={svg}></img>
      </a>
    )
  });
  return itens
}
export default props => (
  <StaticQuery
    query={graphql`
    {
      metadatasJson {
        gaID
        companyInfo{
          name
        }
        social {
          rede
          link
        }
      }
    }
    `}
    render={data =>
      <Footer
        gtag={data.metadatasJson.gaID}
        empresa={data.metadatasJson.companyInfo.name}
        redesSociais={data.metadatasJson.social}
        {...props} />
    }
  />
)
