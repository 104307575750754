import React, { Component } from "react"
import ReactGA from 'react-ga';
import { StaticQuery, graphql } from "gatsby"

import './btn-whatsapp.scss'
class BtnWhatsapp extends Component {
  constructor(props){
    super(props)
    this.state = {link:""}
  }
  componentDidMount(){
    // console.log(this.props.content)
    ReactGA.initialize(this.props.content.gaID);
    let link = this.checkForMobile(this.props.content.contato.whatsapp)
    this.setState({link:link})
    // // console.log(link)
  }
  render(){
    return (
      <ReactGA.OutboundLink className="botao botao--whatsapp" eventLabel="whatsapp"  to={this.state.link}    target="_blank">
          <i className={"fab fa-whatsapp whatsapp-icon"}></i>
          <p className={"text-botao-social"}> Fale Conosco </p>
    </ReactGA.OutboundLink>
  )
}

checkForMobile = (tel) => {
  if (navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return  `https://wa.me/${tel}`;
  }
  else {
    return `https://web.whatsapp.com/send?phone=${tel}`;
  }
}
}

export default props => (
  <StaticQuery
      query={graphql`
    {
      metadatasJson {
        contato {
          whatsapp
        }
        gaID
        gtmID
      }
    }
`}
      render={data =>
          <BtnWhatsapp content={data.metadatasJson}{...props}></BtnWhatsapp>
      }
  />)