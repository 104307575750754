import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import Gallery from "../../components/gallery-full/gallery-full"
import "./gallery-container.scss"

const GalleryContainer = ({fotos, text}) => {
    return (
        <div className="gallery-container__container">
             <h1 className="titulo">Áreas de atuação</h1>
            <Gallery fotos={fotos} legendas={text}></Gallery>
        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
        {
            metadatasJson {
                Atuacao
            }
            allImageSharp(filter: {original: {src: {regex: "/.*img.*/"}}}) {
                edges {
                    node {
                        id
                        original {
                            width
                            height
                            src
                        }
                        fluid (maxWidth: 600){
                            base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }  
    `}

    render={data =>
    <GalleryContainer fotos={data.allImageSharp.edges} text={data.metadatasJson.Atuacao}{...props}></GalleryContainer>
    }
/>)

