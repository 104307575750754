import React from 'react'
import Card from '../../components/card-image-horizontal/card-image'

import './card-equipe.scss'

const createCards = (cardsContent, fotos) => {
  let tmp = []
  cardsContent.forEach((cardContent, i) => {
    tmp.push(
      <Card key={i}
        image={fotos[i].node.fluid}
        name={cardContent.nome}
        subtitle={cardContent.titulo}
        description={cardContent.descricao}
        extra={cardContent.extra}
      >
      </Card>
    )
  });

  return tmp
}
const CardEquipe = ({cardsContent, fotos}) => {

  return (
    <div className="equipe-detalhe__container" id="equipe">
      <div className="equipe-detalhe__wrapper">
        <div className="equipe-detalhe__content">
          <div className="equipe-detalhe__cards-container">
              {createCards(cardsContent, fotos)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardEquipe