import React from "react"
import ReactGA from 'react-ga';
import { graphql } from "gatsby"
import IndexLayout from "../layout/pages/index"
import Header from '../layout/header'
import TopBarFull from '../layout/bars/new-topbar'
import TagManager from 'react-gtm-module'

export default class IndexPage extends React.Component {
  componentDidMount() {
    ReactGA.initialize(this.props.data.metadatasJson.gaID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    TagManager.initialize({ gtmId: this.props.data.metadatasJson.gtmID })
  }


  render() {
    let data = this.props.data.metadatasJson
    return (
      <div>

        <div>
          <Header
          >
          </Header>
          <TopBarFull></TopBarFull>
          <IndexLayout gtag={data.gaID}>
          </IndexLayout>
        </div>
      </div>
    )
  }
}

export const query = graphql`
{
  metadatasJson {
    gaID
    gtmID
  }
}
`

