import React, { Component } from 'react'
import './animated-text.scss'

export default class Animated extends Component {
    constructor(props) {
        super(props)
        this.state = { title: this.props.title[0], counter: 0, animation: "get-in" }
    }

    render() {
        return (
            <div className={"animation " + this.state.animation} >
                <h1 >
                    {this.state.title}
                </h1>
            </div>
        )
    }
    componentDidMount() {
        let interval = setInterval(() => {
            let counter = this.state.counter
            if (this.state.counter < this.props.title.length - 1) {
                counter += 1
                this.setState({
                    title: this.props.title[counter],
                    counter: counter,
                    animation: "get-in"
                })
            } else {
                counter = 0
                this.setState({
                    title: this.props.title[counter],
                    counter: counter,
                    animation: "scale-in"
                })
            }
        }, 5000);

    }

  
} 