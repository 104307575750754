import React from 'react'
import './topAppend.scss'

const TopAppend = ({content, whatsapp}) => {
    return (
        <div className="appendBar" >
               {content}
        </div>
    )
}

export default TopAppend