import React, { Component } from 'react'
// import Paginator from '../../componets-theme/paginator/paginator';
import Img from "gatsby-image"
import Hammer from 'react-hammerjs'
import "./slider.scss"
const Slider = ({ imgSrcs }) => {
    // console.log(imgSrcs)
    return (
        <div className="slider-session">
            {generateImageSlide(imgSrcs)}
        </div>
    )
}

const generateImageSlide = (imgsSrcs) => {
    let slide = []
    imgsSrcs.forEach((imgSrc, i) => {
        slide.push(
            <div className="sliding-background">
                <Img className="slide--content__image" fixed={imgSrc.node.fixed}></Img>
            </div>
        )
    });
    return slide
}
export default Slider