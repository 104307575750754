import React from "react"
import ReactGA from 'react-ga';
import { StaticQuery, graphql } from "gatsby"

import './btn-skype.scss'
const BtnSkype = ({ gaID, link }) => {
  ReactGA.initialize(gaID);
  return (
    <ReactGA.OutboundLink className="botao botao--skype" eventLabel="whatsapp"  to={link}    target="_blank">
          <i className={"fab fa-skype"}></i>
          <p className={"text-botao-social"}> Fale Conosco </p>
    </ReactGA.OutboundLink>
  )
}


export default props => (
  <StaticQuery
      query={graphql`
    {
      metadatasJson {
        gaID
        skype
      }
    }
`}
      render={data =>
          <BtnSkype gaID={data.metadatasJson.gaID} link={data.metadatasJson.skype}{...props}></BtnSkype>
      }
  />)