import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import Marcas from "../../components/marcas/marcas"
import "./marcas-produtos.scss"

const MarcasProdutos = ({ fotos }) => {
    return (
        <div className="marca-container">
            <div className="marca-wrapper">
                <div className="marcas-titulo">Quem Confia</div>
                <Marcas fotos={fotos}></Marcas>
            </div>
        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
        {
            allImageSharp(filter: {original: {src: {regex: "/.*empresa.*/"}}}) {
                edges {
                    node {
                        id
                        original {
                            width
                            height
                            src
                        }
                        fluid (maxWidth: 600) {
                            base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }  
    `}

        render={data =>
            <MarcasProdutos fotos={data.allImageSharp.edges}{...props}></MarcasProdutos>
        }
    />)

