import React from 'react';
import './valores.scss'
import Arrow from '../../icons/js/ArrowPointToRight'
import { StaticQuery, graphql } from "gatsby"

const Valores = ({ motivos }) => (
    <div className="valores-container">
        <div className="valores-wrapper">
            <div className="valores-title">
                <h1>Visão</h1>
            </div>
            <div className="valores-wrapper2">
                <div className="valores-left">
                    <p>{motivos.visao}</p>
                </div>

                <div className="valores-right">
                    {generateCards(motivos.valores)}
                </div>
            </div>
        </div>

    </div>
)

const generateCards = valores => (
    valores.map((valor, i) => (
        <div className="valor-card" key={valor.title}>
            <div className="valor-card-title">
                <i className="material-icons">arrow_forward_ios</i>
                {valor.title}
            </div>
        </div>
    ))
)

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {            
            Missao {
                visao
                valores {
                    title
                    content
                }
            }      
        }
      }      
  `}
        render={data =>
            <Valores
                motivos={data.metadatasJson.Missao}
                {...props} >
            </Valores>
        }
    />)