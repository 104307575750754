import React from 'react';
import Img from 'gatsby-image';
import './card-image.scss';

const CardImage = (props) => {
    return (
    <div className="card card-image-horizontal__container">
        <div className="card-image-horizontal__image-container">
            <Img className="card-image-horizontal__image" fluid={props.image}></Img>
        </div>
        <div className="card-image-horizontal__content">
            <div className="card-image-horizontal__content-wrapper">
                <div className="card-image-horizontal__title">{props.name}</div>
                <div className="card-image-horizontal__subtitle">{props.subtitle}</div>
                <div className="card-image-horizontal__description">{props.description}</div>
                <div className="card-image-horizontal__extra">{props.extra}</div>
            </div>
        </div>
    </div>
)}

export default CardImage
