import React from 'react';
import './novidades.scss'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"

const Novidades = ({ novidades, fotos }) => (
    <div className="novidade-container">
        <div className="novidade-wrapper">
            <div className="novidade-title">
                <h1>{novidades.title}</h1>
            </div>
            <div className="novidade-div-principal">
                <h1 className="principal-h1">Elementos filtrantes</h1>
                <div className="novidade-card-wrapper">
                    {generateCards(novidades.contents, fotos)}
                </div>
            </div>

        </div>

    </div>
)

const generateCards = (novidades, fotos) => {
    return novidades.map((novidade, i) => (
        <div className="novidade-card" key={novidade.title}>
            <h1>{novidade.title}</h1>
            <Img  className="novidade-card-img" fluid={fotos[i].node.fluid}></Img>
            <p>{novidade.content}</p>
        </div>
    ))
}

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {            
            novidades {
                title
                contents {
                    title
                    content
                }
            }      
        }
        allImageSharp(filter: {original: {src: {regex: "/.*filtro.*/"}}}) {
            edges {
                node {
                    id
                    original {
                        width
                        height
                        src
                    }
                    fluid (maxWidth: 600){
                        base64
                        tracedSVG
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
      }      
  `}
        render={data =>
            <Novidades 
            novidades={data.metadatasJson.novidades} 
            fotos={data.allImageSharp.edges}
            {...props} >                
            </Novidades>
        }
    />)