import React from 'react';
import './landingpage-animated.scss';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import logo from '../../images/icon-vertical.png'
import Slider from '../../components/slider/slider'
import AnimatedText from '../../components/animated-text/animated-text'
const LandingpageAnimated = ({ link, content }) => {
  // console.log(link)
  return (
    <div className="view--fixed view--full" id="home">
      <Slider imgSrcs={link}></Slider>
      <div className="view__content view--filter">
        <div className="landingpage__wrapper">
          <div className="view__content__items">
            {/* {animatedText(content.title)} */}
            {showLogo(content.landinglogo)}
            <p className="landingpage-title"> 
              <AnimatedText title={content.title}></AnimatedText>
            </p>
            <p className="landingpage-text"> {content.subline}</p>
          </div>
        </div>

      </div>

    </div>
  )

}

const showLogo = (visible) => {
  if (visible) {
    return <img className="landingpage-logo" alt="Logo da empresa" src={logo} ></img>
  } else {
    return null
  }
}

const animatedText = (texts) => {
  switch (texts.length) {
    case 0:
      return null
      break;
    case 1:
      return <p className="landingpage-title"> {texts[0]}</p>
    default:
      return <p className="landingpage-title"> {texts[0]}</p>
      let content = []
      texts.forEach(text => {
        content.push(<p className="landingpage-title"> {text}</p>)
      });
      let wrap = <div className="animation-wrap"> {content}</div>
      return wrap
      break;
  }
}
export default props => (
  <StaticQuery
    query={graphql`
    {
      content: metadatasJson {
        landing{
          title
          subline
          landinglogo
        }
      }
      image: allImageSharp(filter: {original: {src: {regex: "/.*landing*.*/"}}}) {
        edges {
          node {
            id
            original {
              width
              height
              src
            }
            fixed (width: 4815){
              base64
              tracedSVG
              aspectRatio
              width
              height
              src
              srcSet
              srcWebp
              srcSetWebp
              originalName
            }
          }
        }
      }
    }
`}
    render={data =>
      <LandingpageAnimated
        content={data.content.landing}
        link={data.image.edges}
        {...props}></LandingpageAnimated>
    }
  />)