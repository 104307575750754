import React from "react"
import ReactGA from 'react-ga';
import { StaticQuery, graphql } from "gatsby"
import "./all-purpose-btn.scss"

const AllPurposeBotao = ({ gaID, btnText, link, label }) => {
    ReactGA.initialize(gaID);
    return (
        <ReactGA.OutboundLink className="botao" eventLabel={label} to={link} target="_blank">
            <p>{btnText}</p>
        </ReactGA.OutboundLink>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
        {
            metadatasJson {
              gaID
            }
          }
`}
        render={data =>
            <AllPurposeBotao gaID={data.metadatasJson.gaID}{...props}></AllPurposeBotao>
        }
    />)