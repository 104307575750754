import React from 'react';
import './porquenos.scss'
import { StaticQuery, graphql } from "gatsby"
import PorqueNosComp from '../../components/PorqueNos/porquenos'
// import logo from '../../images/calltoaction.png'

const PorqueNos = ({ calltoaction }) => {
    return (
        <div className="porque-nos__container">
            <div className="porque-nos__wrapper">
                <div className="porque-nos-left" >
                    {/* <img alt="Logo da empresa" src={logo} ></img> */}
                </div>
                <div className="porque-nos-right">
                    <h1>{calltoaction.title}</h1>
                    <PorqueNosComp calltoaction={calltoaction}></PorqueNosComp>
                    <a className="porque-nos__btn">{calltoaction.btnText}</a>
                </div>
            </div>
        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {            
            calltoactionUs {
                title
                content {
                    header
                    text
                }
                btnText
            }
        }
      }      
  `}
        render={data =>
            <PorqueNos
                calltoaction={data.metadatasJson.calltoactionUs}
                {...props}>
            </PorqueNos>
        }
    />)