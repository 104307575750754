import React from 'react'
import './telefoneContato.scss'
const TelefoneContato = ({tel}) => {
    return (
        <a className="botao-topbar" href={"tel: +" + tel} rel="noopener">
            <i className="material-icons bar-contactIcone">phone</i>
            <p className="p-contactTopbar ">
                {generateTelefone(tel)}
            </p>
        </a>
    )
}

const generateTelefone = (telefone) => {
    let tel = ""

    if (telefone.length > 12) {
        tel = `(${telefone.substring(2, 4)}) ${telefone.substring(4, 9)} - ${telefone.substr(-4)}`
    } else {
        tel = `(${telefone.substring(2, 4)}) ${telefone.substring(4, 8)} - ${telefone.substr(-4)}`
    }

    return tel
}

export default TelefoneContato