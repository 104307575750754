import React from "react"
import ReactGA from 'react-ga';
import { StaticQuery, graphql } from "gatsby"

import './btn-instagram.scss'
const BtnInstagram = ({ gaID, link }) => {
  ReactGA.initialize(gaID);
  return (
    <ReactGA.OutboundLink className="botao botao--instagram" eventLabel="whatsapp"  to={link}    target="_blank">
          <i className={"fab fa-instagram"}></i>
          <p className={"text-botao-social"} > Curtir </p>
    </ReactGA.OutboundLink>
  )
}


export default props => (
  <StaticQuery
      query={graphql`
    {
      metadatasJson {
        gaID
        instagram
      }
    }
`}
      render={data =>
          <BtnInstagram gaID={data.metadatasJson.gaID} link={data.metadatasJson.instagram}{...props}></BtnInstagram>
      }
  />)