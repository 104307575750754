import React from 'react'
import { StaticQuery, graphql } from "gatsby"

import './servicos.scss'
import CardServiços from '../card-serviços/card-serviços';

const Servicos = ({ servicos }) => (
    <div className="serviços-container"  >
        <div id="funcionalidades" ></div>
        <div>
            <h1 className="serviços-h">Funcionalidades</h1>
        </div>
        <div className="servicos-cards">
            {createCards(servicos)}
        </div>
    </div>
)
const createCards = servicos => (
    servicos.map(servico => (
        <CardServiços
            titulo={servico.title}
            description1={servico.content}
            key={servico.content}
        >
        </CardServiços>
    ))
)

export default props => (
    <StaticQuery
        query={graphql`
      {
        content: metadatasJson {
            servicos {
            title
            content
          }
        }
      }
  `}
        render={data =>
            <Servicos
                servicos={data.content.servicos}
                {...props}>
            </Servicos>
        }
    />)


