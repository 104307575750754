import React from 'react';
import './quemsomos.scss'
import { StaticQuery, graphql } from "gatsby"

const QuemSomos = ({ obj }) => {
    return (
        <div className="quemSomos-container" id="QuemSomos">
            <div className="quemSomos-wrapper">
                <div className="quemSomos-title">
                    <h1>{obj.title}</h1>
                </div>
                <div className="quemSomos-content">
                    {obj.content.map((element,i) => <p key={element + i}>{element}</p>)}
                </div>
            </div>
        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {            
            QuemSomos {
                title
                content
            }   
        }
      }      
  `}
        render={data =>
            <QuemSomos obj={data.metadatasJson.QuemSomos}{...props}></QuemSomos>
        }
    />)