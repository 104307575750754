import React from 'react';
import './citacao.scss'
import { StaticQuery, graphql } from "gatsby"
import logo from '../../images/citacao-bg.jpg'
import Img from "gatsby-image"

const Citacao = ({ citacao, link }) => {
    return (
        <div className="frase-container">
            <Img fluid={link}></Img>
            <div className="frase-div" style={{ backgroundImage: { logo } }}>
                <p className="p-frase">"{citacao.frase}"</p>
                <p className="p-nome">{citacao.nome}</p>
            </div>
        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {
            citacao {
                frase
                nome
              }
        }
        image: allImageSharp(filter: {original: {src: {regex: "/.*citacao*.*/"}}}) {
            edges {
              node {
                id
                original {
                  width
                  height
                  src
                }
                fluid (maxWidth: 1900){
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
      }      
  `}
        render={data =>
            <Citacao citacao={data.metadatasJson.citacao} link={data.image.edges[0].node.fluid}{...props}></Citacao>
        }
    />)