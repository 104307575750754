import React from 'react';
import './pdf-gallery.scss';
import { StaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
// import logo from '../../images/icon-vertical.png'

const PdfGallery = ({ content, files }) => {
    return (
        <div className="artigos" id="artigos">
            <div>
                <h1 className="titulo">Artigos e Publicações</h1>
            </div>
            <div className="card-pdf__container">
                {generatePDFGallery(files, content)}

            </div>
        </div>
    )

}

const generatePDFGallery = (files, conteudo) => {
    let content = []
    files.forEach((file, i) => {
        content.push(
            <div key={i} className="card card-pdf">
                <h4 className="card-pdf--titulo">{conteudo[i].nome}</h4>
                <p className="card-pdf__text-body">{conteudo[i].resume}</p>
                <div className="card-pdf__button">
                    <a className="botao botao__pdf botao__pdf--style" href={file.node.publicURL}>Ler</a>

                </div>
            </div>
        )
    }, conteudo);
    return content
}
export default props => (
    <StaticQuery
        query={graphql`
    {
        files: allFile(filter: {name: {regex: "/.*artigo*./"}}) {
          edges {
            node {
              relativePath
              absolutePath
              publicURL
            }
          }
        }
        content: metadatasJson {
              pdfgallery {
                nome
                resume
              }
            }         
      }
`}
        render={data =>
            <PdfGallery
                content={data.content.pdfgallery}
                files={data.files.edges}
                {...props}></PdfGallery>
        }
    />)