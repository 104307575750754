import React from "react"
import ReactGA from 'react-ga';
import { StaticQuery, graphql } from "gatsby"

import './btn-facebook.scss'
const BtnFacebook = ({ gaID, link }) => {
  ReactGA.initialize(gaID);
  return (
    <ReactGA.OutboundLink className="botao botao--facebook" eventLabel="whatsapp"  to={link}    target="_blank">
          <i className={"fab fa-facebook"}></i>
          <p className={"text-botao-social"} > Curtir </p>
    </ReactGA.OutboundLink>
  )
}


export default props => (
  <StaticQuery
      query={graphql`
    {
      metadatasJson {
        gaID
        facebook
      }
    }
`}
      render={data =>
          <BtnFacebook gaID={data.metadatasJson.gaID} link={data.metadatasJson.facebook}{...props}></BtnFacebook>
      }
  />)