import React from 'react'
import './sidebar-push.scss'
import { StaticQuery, graphql } from "gatsby"
import logo from '../../images/icon-vertical.png'

const SideBarPush = ({ sessions, state, action }) => {
    return (
        <div className={"sidebar animation " + sideBarState(state)}>
            <div className='sidebar__close' onClick={action}>
                <button className='sidebar__close__button'>
                    <i className="material-icons">
                        close
                    </i>
                </button>
            </div>
            <div className={"sidebar-logo-container"}>
                <div className="logo-div">
                    <p className="logo">Sistema</p>
                    <p className="logo black">OPS</p>
                </div>
            </div>
            <div className="menu">
                <ul className="menu-list">
                    {generateItens(sessions, action)}
                </ul>
            </div>
        </div>
    )

}


const sideBarState = (state) => {
    if (state === undefined) return
    if (state) {
        return "show-sidebar"
    }
    if (!state) {
        return "hide-sidebar"
    }
}

export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {
          sessions {
            sessionName
            sessionContents
            sessionId
            icon
          }
        }
      }      
  `}
        render={data =>
            <SideBarPush sessions={data.metadatasJson.sessions}{...props}></SideBarPush>
        }
    />)


const generateItens = (itensContent, call) => {
    if (itensContent === undefined) return "Esqueceu de passar as Sessions"
    let itens = []
    itensContent.forEach(item => {
        if (item.sessionContents[0] === "self") {
            itens.push(
                <li key={item.sessionName} onClick={call} className={"sidebar-item-text"}>
                    <i className="material-icons sidebar-icon">
                        {item.icon}
                    </i>
                    <a href={'/#' + item.sessionId} className="sidebar-item-text__link" style={{ textDecoration: 'none' }}>
                        {item.sessionName[0].toUpperCase()}{item.sessionName.substring(1)}
                    </a>
                </li>
            )
            return
        }
        if (item.sessionContents[0] === item.sessionName) {
            itens.push(
                <li key={item.sessionName} onClick={call} className={"sidebar-item-text"}>
                    <i className="material-icons sidebar-icon">
                        {item.icon}
                    </i>
                    <a href={item.sessionName} className="sidebar-item-text sidebar-item-text__link" style={{ extDecoration: 'none' }}>
                        {item.sessionName[0].toUpperCase()}{item.sessionName.substring(1)}
                    </a>
                </li>
            )
            return
        }
        if (item.sessionContents.length > 0) {

            itens.push(
                <li key={item.sessionName}>
                    <a>
                        {item.sessionName[0].toUpperCase()}{item.sessionName.substring(1)}
                    </a>
                    <ul>
                        {generateSubItens(item.sessionContents, call)}
                    </ul>
                </li>
            )

        }
    })

    return itens
}
const generateSubItens = (contents, call) => {
    let subitens = []
    contents.forEach(content => {
        subitens.push(
            <li onClick={call} key={content} >
                <a className="navbar-item" href={"/" + content} className={"sidebar-item-text"}>
                    {content[0].toUpperCase()}{content.substring(1)}
                </a>
            </li>
        )
    });
    return subitens
}