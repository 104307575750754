import React from 'react';
import './card-parceiro.scss';

class CardParceiro extends React.Component {
  

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <a className="card-parceiro" href={this.props.link}>
                <img src={this.props.img} className="imgcard-parceiro"></img>
                <div className="texto-card-parceiro">
                    <h1 className="nomecard-parceiro">{this.props.titulo}</h1>
                    <p className="parceiro-txt">{this.props.basico }</p>
                </div>
            </a>
        )
    }
}

export default CardParceiro