import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./call-to-action.scss"
import BtnWhatsappborder from '../../components/btn-whatsapp/btn-whatsapp'
import AllPurposeBotao from '../../components/all-purpose-btn/all-purpose-btn'
import BtnFacebook from '../../components/btn-facebook/btn-facebook'
import BtnEmail from '../../components/btn-email/btn-email'
import BtnInstagram from '../../components/btn-instagram/btn-instagram'
import BtnSkype from '../../components/btn-skype/btn-skype'

const CallToAction = ({ link, content, files }) => {
  return (
    <div className="call-to-action-content" id="calltoaction">
      <Img className="call-to-action-bg" fluid={link}></Img>
      <div className="call-to-action-container">
        <div className="call-to-action-wrapper">
          <h4 className="call-to-action-text"> {content.title}</h4>
          {content.subline.map((value, i) => (
            <div className="btns-wrapper" key={value}>
              <h5 className="call-to-action-subtext">{value}</h5>
              <div className="call-to-action-btns">{pdfLink(i, files)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const pdfLink = (i, PDF) => (
  <a className={"botao-call"} href={PDF[i].node.publicURL} target="_blank" rel="noopener">
    <div className={"botao-call-info"}>
      <p className={"p-botao-call-info"}>Saiba Mais</p>
    </div>
  </a>
)
const chooseBTN = (types, genericText, genericLink) => {
  const btn = [];

  types.forEach(type => {
    switch (type) {
      case "whatsapp":
        btn.push(<BtnWhatsappborder key={type}></BtnWhatsappborder>)
        break;
      case "facebook":
        btn.push(<BtnFacebook key={type}></BtnFacebook>)
        break;
      case "email":
        btn.push(<BtnEmail key={type}></BtnEmail>)
        break;
      case "instagram":
        btn.push(<BtnInstagram key={type}></BtnInstagram>)
        break;
      case "skype":
        btn.push(<BtnSkype key={type}></BtnSkype>)
        break;
      case "generic":
        btn.push(<AllPurposeBotao
          key={type}
          btnText={genericText}
          link={genericLink}
        ></AllPurposeBotao>)
        break;
      default:
        break;
    }
  })
  return btn;
}
export default props => (
  <StaticQuery
    query={graphql`
    {
      content: metadatasJson {
        calltoaction {
          title
          subline
          calltoactionBTN
          genericBtnText
          genericBtnLink
        }
      }
      files: allFile(filter: {name : {regex: "/.*OPS_Doc*./"}}) {
        edges {
          node {
            relativePath
            absolutePath
            publicURL
          }
        }
      }
      image: allImageSharp(filter: {original: {src: {regex: "/.*calltoaction.*/"}}}) {
        edges {
          node {
            id
            original {
              width
              height
              src
            }
            fluid(maxWidth: 1900) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
`}
    render={data =>
      <CallToAction
        content={data.content.calltoaction}
        link={data.image.edges[0].node.fluid}
        files={data.files.edges}
        {...props}></CallToAction>
    }
  />)