import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

const Header = ({ title, meta, application }) => {
    return (
        <Helmet
            title={title}
        >
            <meta http-equiv="Cache-control:public, no-cache, no-store, must-revalidate" content="public"></meta>
            <meta charSet="UTF-8"></meta>
            {/* <meta name="description" content={meta.description}></meta> */}
            <meta name="keywords" content={meta.keywords}></meta>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={meta.description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={meta.url} />
            <link rel="preconnect" href="https://www.google-analytics.com"></link>
            <html lang="en" />
            <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/76d2ad1e-1fa0-49ad-9296-ab6730661971-loader.js" ></script>

            {/* <script type="application/ld+json">
                {`
                {
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "name": ${application.name},
                    "url": ${application.url},
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": ${application.street},
                        "addressLocality": ${application.city},
                        "addressRegion": ${application.state},
                        "postalCode": ${application.zip},
                        "addressCountry": ${application.country}
                    },
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "contactType": "atendimento",
                        "telephone": [${application.telefone}],
                    }
                }
        `}
            </script> */}
        </Helmet>
    )
}
export default props => (
    <StaticQuery
        query={graphql`
      {
        metadatasJson {
            title
            meta {
              description
              keywords
              url
            }
        }
      }
  `}
        render={data =>
            <Header meta={data.metadatasJson.meta} title={data.metadatasJson.title}{...props}></Header>
        }
    />)

