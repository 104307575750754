import React from 'react'
import './Funcionamento.scss'
import { StaticQuery, graphql } from "gatsby"

const Funcionamento = ({ obj, link }) => {
    return (
        <div className="diagrama-boxed">
        <div id="funcionamento" className="id-funcionamento"></div>
            <div className="diagrama-img">
                <img className="imagem-diagrama" src={link.src}>
                </img>
            </div>
            <div className="diagrama-info">
                <div className="diagrama-info-txt">
                    <h1 className="diagrama-h">{obj.title}</h1>
                    {obj.content.map(value => <p  key={value} className="diagrama-txt">{value}</p>)}
                </div>
            </div>
        </div>
    )
}

export default props => (
    <StaticQuery
      query={graphql`
      {
        content: metadatasJson {
            Funcionamento {
            title
            content
          }
        }
        image: allImageSharp(filter: {original: {src: {regex: "/.*diagrama*.*/"}}}) {
          edges {
            node {
              id
              original {
                width
                height
                src
              }
              fluid (maxWidth: 1900){
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
  `}
      render={data =>
        <Funcionamento 
        obj={data.content.Funcionamento} 
        link={data.image.edges[0].node.fluid}
        {...props}>
        </Funcionamento>
      }
    />)